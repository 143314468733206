html,body,#root{
    font-size: 12px !important;
    overflow: hidden;
    margin: 0; 
    height: 100%;
}
a:hover{
    text-decoration: none !important;
    color: #2886ae !important;
}
a{
    transition: color 0.3s;
    text-decoration: none !important;
    color: white !important;
}
h2{
   font-size: 36px !important; 
}
h3{
    font-size: 24px !important;
}
h4{
    font-size: 11px !important;
    margin-top: 2px !important;
}
h5{
    font-size: 11px !important;
}
h6{
    font-size: 11px !important;
}
.bg-green{
    background-color: #5fb67f;
}
.bg-grey{
    background-color: #f7f7f7;
}
.bg-red{
    background-color: #df7892;
}
.text-green{
    color: #5fb67f;
}
.text-yellow{
    color:#EDC331;
}
.song-btn{
    outline: none;
    border: none;
    text-align: left;
    transition: background-color 0.2s, color 0.2s;
    padding: 4px;
    background: none;
}
.song-btn:focus{
    outline:none;
}
.song-btn:hover{
    color: black;
}
.songContainer{
    transition: box-shadow 0.2s;
    padding: 2px 6px;
}
.songContainer:hover,.songContainer:active, .songContainer.active{
    box-shadow: inset 0px 0px 0px 4px #df7892;
} 
.contact-btn{
    outline: none;
    text-align: left;
    transition: background-color 0.2s, color 0.2s;
    padding: 4px;
    background: #EDC331;
    border-radius: 32px;
    padding: 0px 12px;
    border-color: #EDC331;
    border-width: 5px;
    border-style: solid;
    color: black !important;
}
.contact-btn:hover{
    color:white!important;
}
.hoverBlue:hover{
    color:#2886ae!important;
}
.hoverBlue{
    color:#df7892!important;
}

.action-btn{
    outline: none;
    border: none;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s;
    padding: .1rem 0.5rem;
    margin: .4rem 0.5rem;
    color: #2886ae;
    background: none;
    white-space: nowrap;
}
.action-btn:focus{
    outline:none;
}

.btn-link:hover{
text-decoration: none !important;
color: white !important;
}
.btn-link{
   color: #2886ae !important;
   transition: color 0.2s;
    padding: 4px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 16px;
    display: block;
}

.slick-dots{
position: fixed !important;
bottom:0px !important;
left:0;
right:0;
z-index: 1;
background-color: #030304;
}
.slick-dots li.slick-active button:before{
    color: #2886ae !important;
}
.slick-dots li button:before{
    color: white !important;
    opacity: 1!important;
}

.fadeRouter-enter {
    opacity: 0.01;
  }
  .fadeRouter-appear {
    opacity: 0.01;
  }
  .fadeRouter-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-out;
    position: absolute;
    width: 100%;
    margin: auto;
  }
  .fadeRouter-appear-active {
    opacity: 1;
    transition: opacity 150ms ease-out;
  }
  .fadeRouter-exit-active {
    opacity: 0.01;
    transition: opacity 150ms ease-out;
    position: absolute;
    width: 100%;
    margin: auto;
  }